<template>
  <v-card class="OnHover pa-3">
    <v-simple-table width="50%">
      <tr>
        <td>
          <span class="text--header--breadcrumb pl-3">
            {{ filterText }}
          </span>
        </td>
        <td>
          <v-spacer />
          <v-text-field
            v-model="searchEquipment"
            append-icon="mdi-magnify"
            class="ml-auto mb-2"
            label="Search"
            hide-details
            single-line
            style="max-width: 250px"
          />
        </td>
      </tr>
    </v-simple-table>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 15, 30, 50, -1] }"
      :search="searchEquipment"
      @click:row="handleRowClick"
      dense
    >
      <template v-slot:item.sn="{ item }"> <v-icon v-if="item.rowIndex == selectedRow"> mdi-forward </v-icon> {{ item.sn }} </template>
    </v-data-table>
    <v-btn color="primary" dark class="mb-2 pa-0" :before-generate="beforeGenerateExcel" :before-finish="beforeFinishExcel" @click="handleDownload">
      Download <v-icon small class="mr-1" right> mdi-download </v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import Utils from '@/api/util'
import store from '@/store'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { findEquipmentDataTable } from '@/api/PouchDaoReport'

export default {
  name: 'EquipmentDataTable',
  // props: {
  //   category: {
  //     type: String,
  //     default: 'DOMESTIC DATA',
  //   },
  // },
  computed: {
    ...mapGetters('equipment', { refreshRequired: 'refreshRequired', snTableFilter: 'snTableFilter' }),
    ...mapGetters('app', {
      buybackAvailable: 'buybackAvailable'
    }),

    filterText() {
      if (this.snTableFilter.category === 'deviceType') {
        return this.snTableFilter.deviceType
      }
      if (this.snTableFilter.category === 'make') {
        return this.snTableFilter.deviceType + '  >  ' + this.snTableFilter.make
      }
      if (this.snTableFilter.category === 'deviceName') {
        return this.snTableFilter.deviceType + '  >  ' + this.snTableFilter.make + '  >  ' + this.snTableFilter.deviceName
      }
      return null
    }
  },
  watch: {
    snTableFilter: {
      handler() {
        // deviceType
        // make
        // deviceName
        console.log(
          'snTableFilter changed to deviceType ' +
            this.snTableFilter.deviceType +
            ' make ' +
            this.snTableFilter.make +
            ' deviceName ' +
            this.snTableFilter.deviceName
        )
        this.updateEquipmentDetailTable(this.snTableFilter)
      }
    },
    refreshRequired: {
      handler() {
        if (this.refreshRequired) {
          this.updateHeaders()
        }
      }
    }
  },
  data() {
    return {
      selectedRow: -1,
      searchEquipment: '',
      excelFileName: 'Equipment Service List Export',
      excelFileType: 'xlsx',
      headers: [
        {
          text: 'Mobile Numbers',
          align: 'left',
          sortable: false,
          value: 'sn'
        },
        { text: 'Jan', value: '0' },
        { text: 'Feb', value: '0' },
        { text: 'Mar', value: '0' },
        { text: 'Apr', value: '0' },
        { text: 'May', value: '0' }
      ],
      tableData: []
    }
  },
  mounted() {
    this.updateHeaders()
  },
  methods: {
    handleRowClick(value) {
      console.log('handleRowClick rowindex is ' + value.rowIndex)
      this.selectedRow = value.rowIndex
      store.dispatch('equipment/updateSnFilter', { sn: value.sn })
    },
    updateEquipmentDetailTable(snTableFilter) {
      // if (snTableFilter.deviceName) {
      //   console.log('deviceName is ' + this.snTableFilter.deviceName)
      // }
      findEquipmentDataTable(snTableFilter).then((result) => {
        this.selectedRow = -1
        this.resetData(result)
      })
    },
    updateHeaders() {
      // try to update the headers...
      let dataHeaders = []
      findEquipmentDataTable({ category: 'ALL' }).then((result) => {
        dataHeaders.push({ text: 'Mobile No.', value: 'sn', align: 'left' })
        dataHeaders.push({ text: 'Name', value: 'userName' })
        //dataHeaders.push({ text: 'Device Make', value: 'make' })
        dataHeaders.push({ text: 'Device Name', value: 'deviceName' })
        dataHeaders.push({ text: 'Device Model', value: 'model' })
        dataHeaders.push({ text: 'Trade in Value', value: 'tradeInValue' })
        this.buybackAvailable && dataHeaders.push({ text: 'Buyback Price', value: 'modelPrice' })
        //dataHeaders.push({ text: 'Operating System', value: 'os' })
        dataHeaders.push({ text: 'IMEI', value: 'imei' })
        dataHeaders.push({ text: 'SIM Number', value: 'sim' })
        this.headers = dataHeaders
        this.resetData(result)
      })
    },
    resetData(result) {
      let dataRows = []
      let rowIndex = 0
      result.forEach((row) => {
        const data = row.doc
        data.rowIndex = rowIndex++
        if (data.modelPrice !== null) {
          data.modelPrice = Utils.formatCurrency(data.modelPrice)
        }
        dataRows.push(data)
      })
      this.tableData = dataRows
      store.dispatch('equipment/refreshRequired', false)
    },
    exportToExcel() {
      return this.tableData
    },
    beforeGenerateExcel() {
      store.dispatch('app/setProgress', true)
    },
    beforeFinishExcel() {
      store.dispatch('app/setProgress', false)
    },
    getColor(item) {
      // console.log('sn is ' + item.sn)
      return 'green'
    },
    handleDownload() {
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = []
        const filterVal = []

        this.headers.forEach((item, index) => {
          tHeader.push(item.text)
          filterVal.push(item.value)
        })

        const list = this.tableData
        const data = list.map((v) => filterVal.map((j) => v[j]))
        excel.export_json_to_excel({
          header: tHeader,
          sheetTitle: 'Services',
          data,
          filename: this.excelFileName,
          autoWidth: true,
          bookType: this.excelFileType
        })
      })
    }
  }
}
</script>

<style scoped></style>
