<template>
  <v-container id="equipment" tag="section" fluid class="pt-0" style="margin-bottom: 300px; min-width: 1366px; max-width: 1920px">
    <v-row class="pt-0" v-show="showTopTenPanel">
      <v-col cols="12" sm="4" lg="4" class="pt-0">
        <v-card class="pl-3" height="100%">
          <span class="v-card__title font-weight-light pa-2">Device Type</span>
          <equipment-top-ten-pie-chart data-category="deviceType" />
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="4" lg="4" class="pt-0 pl-0">
        <v-card class="pl-3" height="100%">
          <span class="v-card__title font-weight-light pa-2">Device Make</span>
          <equipment-top-ten-pie-chart data-category="make" />
        </v-card>
      </v-col> -->
      <v-col cols="12" sm="4" lg="4" class="pt-0 pl-0">
        <v-card class="pl-3" height="100%">
          <span class="v-card__title font-weight-light pa-2">{{ this.showBuybackPrice ? 'Device Buyback' : 'Device Name' }}</span>
          <equipment-top-ten-pie-chart data-category="deviceName" />
          <v-switch
            label="Buyback Price"
            v-model="showPrice"
            v-show="this.buybackAvailable"
            style="float: right; margin-right: 25px; margin-top: 0px"
            @click="showHidePrice"
          ></v-switch>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" lg="4" class="pt-0">
        <v-card class="pl-3" height="100%">
          <span class="v-card__title font-weight-light pa-2">Device in Contract</span>
          <equipment-top-ten-pie-chart data-category="mroContractDeviceName" />
        </v-card>
      </v-col>
    </v-row>
    <v-breadcrumbs :items="crumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="props">
        <v-chip
          :color="!props.item.disabled ? '' : '#FCDBE6'"
          :text-color="!props.item.disabled ? '' : '#EB3573'"
          @click="handleBreadCrumbClick(props.item.code)"
        >
          {{ props.item.text }}
        </v-chip>
      </template>
    </v-breadcrumbs>
    <equipment-device-summary-table v-show="showEquipmentSummary" />
    <equipment-data-table v-show="showSnTable" />
    <service-number-page v-if="showSnDetail" :sn-data="snFilter.sn" />
  </v-container>
</template>

<script>
import store from '@/store'
import EquipmentTopTenPieChart from '@/views/blitz/components/apex/EquipmentTopTenPieChart'
import EquipmentDataTable from '@/views/blitz/components/EquipmentDataTable'
import EquipmentDeviceSummaryTable from '@/views/blitz/components/EquipmentDeviceSummaryTable'
import ServiceNumberPage from '@/views/blitz/pages/ServiceNumberPage'
import { mapGetters } from 'vuex'

export default {
  name: 'Equipment',
  components: {
    EquipmentTopTenPieChart,
    EquipmentDataTable,
    EquipmentDeviceSummaryTable,
    ServiceNumberPage
  },
  computed: {
    ...mapGetters('app', { buybackAvailable: 'buybackAvailable', showBuybackPrice: 'showBuybackPrice' }),
    ...mapGetters('equipment', {
      snTableFilter: 'snTableFilter',
      equipmentChartFilter: 'equipmentChartFilter',
      snFilter: 'snFilter'
    }),

    showSnTableFilter: function () {
      if (this.snTableFilter.category === 'deviceType') {
        return true
      }
      if (this.snTableFilter.category === 'make') {
        return true
      }
      if (this.snTableFilter.category === 'deviceName') {
        return true
      }
      if (this.snTableFilter.category === 'mroContractDeviceName') {
        return true
      }
      return false
    }
  },
  watch: {
    equipmentTableFilter: {
      handler() {
        console.log('refreshRequired changed to category ' + this.equipmentTableFilter.category + ' label ' + this.equipmentTableFilter.label)
        this.showEquipmentSummary = true
        this.showSnTable = false
        this.showSnDetail = false
        // Reset brumbs..
        this.crumbs = this.summary_crumbs
      }
    },
    snTableFilter: {
      handler() {
        console.log('refreshRequired changed to category ' + this.snTableFilter.category + ' label ' + this.snTableFilter.label)
        // Reset brumbs..
        this.showEquipmentSummary = false
        this.showSnTable = true
        this.showSnDetail = false
        this.crumbs = this.sn_table_crumbs
      }
    },
    snFilter: {
      handler() {
        console.log('refreshRequired changed to sn ' + this.snFilter.sn)
        if (this.snFilter.sn) {
          // Reset brumbs..
          this.showEquipmentSummary = false
          this.showSnTable = false
          this.showSnDetail = true
          this.showTopTenPanel = false
          if (this.snFilter.isDeviceInContract) {
            this.crumbs = this.sn_device_in_contract_crumbs
          } else {
            this.crumbs = this.sn_crumbs
          }
        } else {
          this.showEquipmentSummary = false
          this.showSnTable = true
          this.showTopTenPanel = true
          this.showSnDetail = false
          this.crumbs = this.sn_table_crumbs
        }
      },
      showBuybackPrice: {
        handler() {
          if (this.buybackAvailable) {
            this.showPrice = this.showBuybackPrice
          } else {
            this.showPrice = false
          }
        }
      }
    }
  },

  data() {
    return {
      breadFilter: false,
      showEquipmentSummary: true,
      showSnTable: false,
      showSnDetail: false,
      showTopTenPanel: true,
      showPrice: false,
      crumbs: [
        {
          text: 'Equipment Summary',
          disabled: false,
          code: 'equipmentSummary',
          href: 'breadcrumbs_dashboard'
        }
      ],
      summary_crumbs: [
        {
          text: 'Equipment Summary',
          disabled: false,
          code: 'equipmentSummary',
          href: 'breadcrumbs_dashboard'
        }
      ],
      sn_table_crumbs: [
        {
          text: 'Equipment Summary',
          disabled: false,
          code: 'equipmentSummary',
          href: 'breadcrumbs_dashboard'
        },
        {
          text: 'Service List',
          code: 'serviceNumbers',
          disabled: true,
          href: 'breadcrumbs_dashboard'
        }
      ],
      sn_crumbs: [
        {
          text: 'Equipment Summary',
          disabled: false,
          code: 'equipmentSummary',
          href: 'breadcrumbs_dashboard'
        },
        {
          text: 'Service List',
          code: 'serviceNumbers',
          disabled: false,
          href: 'breadcrumbs_dashboard'
        },
        {
          text: 'Service Detail',
          code: 'sn',
          disabled: true,
          href: 'breadcrumbs_dashboard'
        }
      ],
      sn_device_in_contract_crumbs: [
        {
          text: 'Equipment Summary',
          disabled: false,
          code: 'equipmentSummary',
          href: 'breadcrumbs_dashboard'
        },
        {
          text: 'Service Detail',
          code: 'sn',
          disabled: true,
          href: 'breadcrumbs_dashboard'
        }
      ]
    }
  },
  mounted() {
    // this.$gtag.event('mounted', { page: 'equipment' })
    // this.$gtag.event('login', { method: 'Google' })
    if (this.buybackAvailable) {
      this.showPrice = this.showBuybackPrice
    } else {
      this.showPrice = false
    }
    this.$gtag.pageview({
      page_path: '/equipment'
    })
  },
  methods: {
    handleBreadCrumbClick(code) {
      console.log('handleBreadCrumbClick value is ' + code)
      if (code === 'equipmentSummary') {
        this.showEquipmentSummary = true
        this.showSnTable = false
        this.showSnDetail = false
        this.showTopTenPanel = true
        this.crumbs = this.summary_crumbs
      }
      if (code === 'serviceNumbers') {
        this.showEquipmentSummary = false
        this.showSnTable = true
        this.showSnDetail = false
        this.showTopTenPanel = true
        this.crumbs = this.sn_table_crumbs
      }
      // if (code === 'sn') {
      //   this.showEquipmentSummary = false
      //   this.showSnTable = false
      //   this.showSnDetail = true
      //   this.showTopTenPanel = false
      //   this.crumbs = this.sn_crumbs
      // }
    },
    showHidePrice() {
      store.dispatch('app/setShowBuybackPrice', this.showPrice)
    }
  }
}
</script>

<style scoped>
.disabled {
  color: grey;
  pointer-events: none;
}
</style>
