<template>
  <div>
    <apexchart type="donut" :options="chartOptions" :series="pieSeries" min-height="280px" max-height="300px" />
    <!--    <apexchart-->
    <!--      type="radialBar"-->
    <!--      height="465"-->
    <!--      :options="radialChartOptions"-->
    <!--      :series="radialSeries"-->
    <!--    />-->
  </div>
</template>

<script>
import { findTopTenEquipment, findTopTenEquipmentBuybackPrice, filterTopTenEquipmentChart } from '@/api/PouchDaoReport'
import { mapGetters } from 'vuex'
import store from '@/store'
import Utils from '@/api/util'
import { appConfig } from '@/appConfig'

//
// import { createNamespacedHelpers } from 'vuex'
// const { mapGetters } = createNamespacedHelpers('usage')

export default {
  name: 'UsageSMSTopTenPieChart',
  props: {
    dataCategory: {
      type: String,
      default: 'make'
    }
  },
  computed: {
    ...mapGetters('app', {
      customerInfo: 'customerInfo',
      snCount: 'snCount',
      showBuybackPrice: 'showBuybackPrice'
    }),
    ...mapGetters('equipment', {
      refreshRequired: 'refreshRequired',
      equipmentChartFilter: 'equipmentChartFilter'
    })
  },
  watch: {
    equipmentChartFilter: {
      // this is set from EquipmentTopTenPieChart.vue which dispatch the value to vuex
      handler() {
        console.log('equipmentChartFilter for ' + this.dataCategory + ' filter ', this.equipmentTableFilter)
        if (this.equipmentChartFilter && this.dataCategory !== this.equipmentChartFilter.key) {
          this.filterChart(this.equipmentChartFilter)
        } else if (this.equipmentChartFilter && !this.equipmentChartFilter.key) {
          this.switchTopTen(this.dataCategory) // hard coded to 'make' at the moment
        }
      }
    },
    showBuybackPrice: {
      handler() {
        console.log(`showBuybackPrice fired ${this.showBuybackPrice} category ${this.dataCategory}`)
        if (this.showBuybackPrice && this.dataCategory === 'deviceName') {
          this.switchTopTenBuybackPrice(this.dataCategory)
        } else {
          this.switchTopTen(this.dataCategory)
        }
      }
    }
  },
  data() {
    return {
      refreshData: true,
      pieSeries: [44, 55, 13, 43, 22],
      chartOptions: {
        // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        chart: {
          events: {
            dataPointSelection: (e, chart, opts) => {
              // you can call Vue methods now as "this" will point to the Vue instance when you use ES6 arrow function
              this.topTenPointSelected(opts)
            },
            updated: function (chart) {
              // console.log('Chart updated event triggered')
            }
          }
        },
        plotOptions: {
          pie: {
            customScale: 0.9,
            expandOnClick: false,
            donut: {
              size: '60%',
              labels: {
                show: true
              }
            }
          }
        },
        noData: {
          text: 'No data',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#BEC932',
            fontSize: '12px',
            fontFamily: undefined
          }
        },
        dataLabels: {
          enabled: true
        },
        tooltip: {
          enabled: false
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
        colors: appConfig.colors,
        fill: {
          type: 'gradient'
        },
        legend: {
          width: 220,
          horizontalAlign: 'left',
          offsetX: 0,
          offsetY: -20,
          formatter: function (seriesName, opts) {
            // console.log('legend seriesname ', seriesName)
            // return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]]
            const n = 20 // length of word
            const trimmedName = seriesName.length > n ? seriesName.substr(0, n - 1) + '&hellip;' : seriesName
            return [trimmedName]
          }
        }
      }
    }
  },
  mounted() {
    if (this.refreshData) {
      if (this.showBuybackPrice && this.dataCategory === 'deviceName') {
        this.switchTopTenBuybackPrice(this.dataCategory)
      } else {
        this.switchTopTen(this.dataCategory) // hard coded to 'make' at the moment
      }
      this.refreshData = false
    }
  },
  methods: {
    topTenPointSelected(opts) {
      // this is the clicked event from apex pie chart
      const filterEvent = { category: this.dataCategory, label: this.chartOptions.labels[opts.dataPointIndex] }
      console.log('topTenPointSelected ', filterEvent)
      store.dispatch('equipment/updateEquipmentTableFilter', filterEvent)
    },
    switchTopTen(dataCategory) {
      // omly called on initial load
      console.log('switchTopTen ' + dataCategory + ' calling findMonthlyTopTen')
      findTopTenEquipment(dataCategory).then((result) => {
        console.log('findTopTenEquipment ' + dataCategory + ' completed')
        this.updateCartData(result)
      })
    },
    switchTopTenBuybackPrice(dataCategory) {
      findTopTenEquipmentBuybackPrice(dataCategory).then((result) => {
        console.log('findTopTenEquipmentBuybackPrice ' + dataCategory + ' completed')
        this.updateCartData(result)
      })
    },
    filterChart(equipmentChartFilter) {
      console.log('filterChart data category ' + this.dataCategory + ' filter key is ', equipmentChartFilter.key)
      filterTopTenEquipmentChart(this.dataCategory, equipmentChartFilter).then((result) => {
        console.log('filterChart for ' + this.dataCategory, result)
        this.updateCartData(result)
      })
      return false
    },
    updateCartData(chartResult) {
      let chartData = []
      let labels = []
      const showPriceFormat = this.showBuybackPrice && this.dataCategory === 'deviceName'
      // try to reverse sort
      // result.sort((a, b) => parseFloat(b.qty) - parseFloat(a.qty))
      chartResult.forEach((pieData) => {
        labels.push(pieData.sn + '')
        chartData.push(pieData.qty)
      })
      this.pieSeries = chartData // copy the array...
      this.chartOptions = {
        labels: labels, // !! do not change this as the topTenPointSelected() needs this to lookup the item click in order to filter the equipment table
        plotOptions: {
          // This option is to format the value label of the pieChart
          pie: {
            donut: {
              labels: {
                value: {
                  formatter: function (labelValue, opts) {
                    if (showPriceFormat) {
                      return Utils.formatCurrency(labelValue)
                    } else {
                      return labelValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped></style>
